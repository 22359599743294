import Ad from 'components/Ad';
import Content from 'components/Content';
import Social from 'components/Social';
import site from 'data/site.json';
import React from 'react';
import styled from 'styled-components';
import theme from 'styles/theme';
import SEO from 'utils/SEO';

const Post = styled.div`
	display: grid;
	grid-gap: ${theme.size(2)};
	& > * {
		background: ${theme.colors.background[0]};
		border-radius: ${theme.size(0.5)};
	}
	.canonical {
		${theme.type.p2};
	}
	${theme.media.notlarge} {
		.ad {
			width: calc(100% + ${theme.size(4)} + ${theme.size(4)});
			margin-left: -${theme.size(4)};
			margin-bottom: ${theme.size(4)};
			> * {
				margin: 0 auto;
				display: block;
			}
		}
	}
	small a {
		&:hover {
			text-decoration: underline;
		}
	}
`;
const Byline = styled.div`
	${theme.type.p2};
	text-transform: uppercase;
	margin-bottom: ${theme.size(2)};
	> * {
		display: inline-block;
		margin-right: ${theme.size(2)};
	}
	.category {
		color: ${theme.colors.base[0]};
		font-weight: bold;
	}
	.date {
		color: ${theme.colors.foreground[1]};
	}
`;
const Text = styled.div`
	display: inline-block;
	width: 100%;
	.body > * {
		display: block;
		margin-bottom: ${theme.size(4)};
		max-width: 100% !important;
	}
	img {
		width: 60%;
		height: auto;
		float: left;
		margin: ${theme.size(4)};
		margin-left: 0;
		margin-top: 0;
	}
	h1 {
		font-size: 24px;
	}
	${theme.media.large} {
		p {
			display: inline;
		}
		& > [data-type] {
			display: none;
		}
		& > [data-type='in_content'],
		& > [data-type='leaderboard_bot'] {
			display: inline;
			float: left;
			width: auto;
			margin: ${theme.size(4)};
			margin-left: 0;
		}
		& > [data-type='in_content'] {
			margin-top: ${theme.size(2)};
		}
		& > [data-type='leaderboard_bot'] {
			margin-left: -${theme.size(4)};
		}
	}
`;

export default props => {
	const post = props.pageContext.data;
	return (
		<Post>
			<SEO title={post.title} image={post.cover} href={props.path} />
			<Content>
				<Byline>
					<a className="category" href={post.slug}>
						{post.category}
					</a>
					<div>
						{post.author.first_name} {post.author.last_name}
					</div>
				</Byline>
				<h1>{post.title}</h1>
				<small>{post.excerpt}</small>
				<img alt={post.title} src={post.cover} />
				<Text>
					<Ad type="in_content"></Ad>
					<Ad type="mpu_2"></Ad>
					<div className="body" dangerouslySetInnerHTML={{ __html: post.content }} />
				</Text>
				{/* <Social
					href={site.domain + '/' + props.pageContext.post}
					image={site.domain + post.facebook_image.large}
				></Social> */}
			</Content>
			<Ad type="leaderboard_bot" />
			<Ad type="mpu_3"></Ad>
		</Post>
	);
};
